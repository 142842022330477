<template>
  <v-container
    ><v-row>
      <v-card>
        <v-card-text>
          <v-tabs v-model="tabs">
            <v-tab href="#tab-1">Datenschutzerklärung</v-tab>
            <v-tab href="#tab-2">Privacy Policy</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item value="tab-1"><privacy-declaration-de /></v-tab-item>
            <v-tab-item value="tab-2"><privacy-declaration-en /></v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card> </v-row
  ></v-container>
</template>
<script>
import PrivacyDeclarationDe from '@/main/legal/privacy/PrivacyDeclarationDe'
import PrivacyDeclarationEn from '@/main/legal/privacy/PrivacyDeclarationEn'
export default {
  components: { PrivacyDeclarationEn, PrivacyDeclarationDe },
  data() {
    return {
      tabs: null
    }
  }
}
</script>
